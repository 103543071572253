<template>
  <div>
    <v-container fluid class="px-10">
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2>Invoices</h2>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet elevation="4">
            <!--Tabs-->
            <v-tabs fixed-tabs background-color="indigo" dark>
              <v-tab> Orders To Invoice </v-tab>
              <v-tab> Awaiting Payments </v-tab>
              <v-tab> Paid In Full </v-tab>
              <!--Data tables-->
              <v-tab-item>
                <OrdersToInvoiceTable />
              </v-tab-item>
              <v-tab-item> <UnpaidInvoicesTable /> </v-tab-item>
              <v-tab-item> <PaidInFullInvoiceTable /> </v-tab-item>
            </v-tabs>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "InvoicesTable",
  components: {
    OrdersToInvoiceTable: () =>
      import(/* webpackChunkName: "invoices" */ "./OrdersToInvoiceTable.vue"),
    UnpaidInvoicesTable: () =>
      import(/* webpackChunkName: "invoices" */ "./UnpaidInvoicesTable.vue"),
    PaidInFullInvoiceTable: () =>
      import(/* webpackChunkName: "invoices" */ "./PaidInFullInvoiceTable.vue"),
  },
  data() {
    return {
      model: "tab-2",
    };
  },
};
</script>